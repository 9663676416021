.contact {
    padding-bottom: 40px; 
    background-color: rgb(248, 248, 147);
    text-align: center; 
    padding-top: 10px; 
    margin-top: 20px; 
}

.contact h2 {
    margin: 0; 
 margin-top: 10px;  
}

.contact iframe {
    height: 90vw;
width: 90vw; 
}

@media only screen and (min-width: 320px) {
    .contact iframe {
        height: 80vw;
    width: 80vw; 
    }
}

@media only screen and (min-width: 540px) {
    .contact iframe {
        height: 60vw;
    width: 60vw; 
    }
}
 
@media only screen and (min-width: 740px) {
    .contact iframe {
        height: 40vw;
    width: 40vw; 
    }
}
       

