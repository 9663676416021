.header {
padding: 0; 
text-align: center; 
background-color: rgb(167, 216, 249); 
}

.header .hall-img {

    width: 100%; 
    height: auto;  
  }
  
.heading .heading-text {
    margin: 0; 
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);

    color: white;  
    font-weight: bold;
    width: 90%; 
    background: rgb(0, 0, 0, 0.7);   
    text-align: center; 

}

.heading .heading-text h1 {
margin: 0; 
font-size: 20px; 
}

.heading .heading-text p {
    margin: 0;
    font-size: 12px; 

    }

.header .navbar {
    padding: 10px 0 10px; 
    background-color: rgb(167, 216, 249); 

}

.header .navbar {
    margin:  0; 
    text-decoration: none; 
}

.header .navbar button {
    font-size: 20px; 
    width: 200px; 
    height: 30px; 
    background-color: rgb(0, 175, 0); 
    border: none; 
    border-radius: 10px;
    color: white; 
    Font-Family: 'Vollkorn', Serif;
    margin: 10px 0; 
}

.header .navbar button:hover {
  cursor:pointer; 
}

.header .navbar .NavLinkDiv {
    margin: 10px 0; 

}

.header .navbar .NavLink {
    text-decoration: none;
    color: black; 
    Font-Family: 'Vollkorn', Serif;

}

.header .navbar .NavLink:hover {
    text-decoration: underline; 

}

@media only screen and (min-width: 500px) {

    .heading .heading-text {
        top: 45px; 
    }
    .heading .heading-text h1 {

        font-size: 30px; 
        }
        
        .heading .heading-text p {

            font-size: 20px; 
        
            }

}

@media only screen and (min-width: 540px) {
    .header h1 {
        font-size: 40px; 
         
        }

}

@media only screen and (min-width: 630px) {
    .header h1 {
        font-size: 50px; 
         
        }
  
        .heading .heading-text {
            top: 60px; 
            width: 50%; 
        }

        .heading .heading-text h1 {
    
            font-size: 30px; 
            }
            
            .heading .heading-text p {
    
                font-size: 20px; 
            
                }

        .header .hall-img {
            padding-top: 2%; 
            width: 80%; 
        }
}

@media only screen and (min-width: 800px) {
    .header .navbar .NavLink {
     font-size: 20px; 
    
    }
    .header .hall-img {
        padding-top: 2%; 
        width: 60%; 
    }
    .heading .heading-text {
        top: 60px; 
        width: 40%; 
    }

}

@media only screen and (min-width: 1030px) {
    .NavLinks {
        display: flex; 
        justify-content: space-around; 
    }
    .heading .heading-text {
        top: 80px; 
    }

    .header .hall-img {
        padding-top: 1%; 

    }

}
