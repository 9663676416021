.committee {
    padding-bottom: 40px; 
    background-color: rgb(248, 248, 147);
    padding-left: 20px; 
    padding-top: 10px; 
    margin-top: 20px; 
    overflow-y: hidden;

}

.committee iframe {
    height: 1000px; 
    width: 1000%;  
    border: 0;
    transform: scale(0.33);
    transform-origin: 0 0;
    margin-bottom: -700px; 
}

@media only screen and (min-width: 420px) {
    .committee h2 {
        padding-left: 60px; 
    }

    .committee iframe {
        transform: scale(0.45);
        margin-bottom: -500px; 
    }

}

@media only screen and (min-width: 600px) {
    .committee iframe {
        transform: scale(0.7);
        margin-bottom: -300px; 
    }

}

@media only screen and (min-width: 810px) {
    .committee iframe {
        transform: scale(0.9);
        margin-bottom: -100px; 
    }

}



       

