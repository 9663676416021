.instagram {

    padding: 10px 5px 40px;

}

.instagram p {
    font-size: 30px; 
}






       
       

