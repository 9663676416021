.home {
    background-color: rgb(248, 248, 147); 
    padding-top: 30px; 
    padding-bottom: 60px; 
    text-align: center; 
}

.home h2 {
    padding-top: 40px;
    margin-bottom: 0;
    padding-left: 30px; 
    padding-right: 30px;
    padding-bottom: 0; 
    margin-top: 0;   
}

.home .intro {
    padding-top: 10px; 
    margin-top: 10px; 
    text-align: center; 
    padding-left: 30px; 
    padding-right: 30px;  
    margin-bottom: 0; 
}

.home img {
    width: 90%; 
    padding-left: 0%; 
    margin-left: 0%; 
    margin-top: 20px; 
    margin-bottom: 50px; 
}

@media only screen and (min-width: 500px) {
    .home .intro {
        padding-left: 20px; 
        padding-right: 20px; 
        padding-top: 20px; 
    }
    .home img {
        width: 70%; 
 
    }
    
    }

    @media only screen and (min-width: 780px) {
        .home .intro {
            padding-left: 50px; 
            padding-right: 50px; 

            font-size: 20px;  
        }
        .home img {
            width: 50%; 
     
        }


        
        }
       
        @media only screen and (min-width: 950px) {
            .home .intro {
                padding-left: 200px; 
                padding-right: 200px;


 
            }

            
            }



