.calendar {
    background-color: rgb(248, 248, 147);
    padding-bottom: 40px;  
}

.calendar .googleCalendar {
    margin-top: 10px; 
margin-left: 2.5%; 
margin-bottom: 50px; 
width: 90%; 
margin-left: 5%; 

}

.calendar h2 {
padding-left: 40px; 
    margin: 20px 0 10px; 
    padding-top: 10px; 

}

.calendar .clubs {
    height: 1100px; 
    width: 2000px;  
    border: 0;
    transform: scale(0.33);
    transform-origin: 0 0;
    margin-bottom: -800px; 
    padding-left: 40px; 
}

.calendar .contacts {
    margin-bottom: -900px; 
}

@media only screen and (min-width: 420px) {
    .calendar .clubs {
        transform: scale(0.45);
        margin-bottom: -675px; 
    }

    .calendar .contacts {
        margin-bottom: -800px; 
    }

    .calendar h2 {
        padding-left: 80px; 

    }

}

@media only screen and (min-width: 600px) {
    .calendar .clubs {
        transform: scale(0.68);
        margin-bottom: -475px; 
    }

    .calendar .contacts {
        margin-bottom: -650px; 
    }

    .calendar h2 {
        padding-left: 160px; 

    }

}

@media only screen and (min-width: 810px) {
    .calendar .clubs {
        transform: scale(0.9);
        margin-bottom: -250px; 
    }
    .calendar .contacts {
        margin-bottom: -500px; 
    }
    .calendar h2 {
        padding-left: 320px; 

    }

}

@media only screen and (min-width: 1030px) {
    .googleCalendar  {
    width: 80%;  
    margin-left: 10%; 

    
    }
}


       
       

